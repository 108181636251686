import { css } from "@emotion/core";
import { mq } from "../../../cssInJs";

const step = css(
  mq({
    flex: "0 0 100%",
    opacity: 0,
    padding: "2rem 1rem 2rem",
    // maxHeight: '300px',
    transition: "all 0.3s",
  })
);

export default {
  cart: css({
    position: "relative",
    margin: "auto",
    marginBottom: "3rem",
    textAlign: "left",
  }),

  stepsWrapper: css({
    overflow: "hidden",
  }),
  steps: css({
    display: "flex",
    transition: "all 1s cubic-bezier(0.22, 0.61, 0.36, 1)",
    "& h3, & h4": {
      textTransform: "uppercase",
      marginBottom: ".8rem",
    },
  }),
  step,
  stepCurrent: css(step, {
    // maxHeight: '2000px',
    opacity: 1,
    background: "rgba(255,255,255,0.5)",
  }),
  stepPrev: css(step, {}),
  stepNext: css(step, {}),
  stepLast: css({
    //maxHeight: '1000px',
    opacity: 1,
  }),
};
