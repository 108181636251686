import { createValidator } from "../../../../lib/form/validatorHelper";

export function allowedPostcode(message = null, opt = null) {
  return createValidator(
    "allowed state",
    message,
    opt,
    function (control, e, validateOpt) {
      if (validateOpt.triggeredByChange && control.incomplete) return null;
      return !isEmpty(control.value) && !isAllowedPostcode(control.value)
        ? message ||
            "We are unable to ship any honey or bee products to this area due to quarantine restrictions"
        : null;
    }
  );
}

// return true if it is right postcode
// return false if it is not right postcode
function isAllowedPostcode(val) {
  const notAllowedArea = ["5223", "5222", "5220", "5221"];
  return !notAllowedArea.includes(val);
}

function isEmpty(val) {
  if (Array.isArray(val)) return val.length === 0;
  return !val && val !== 0;
}

export function containHoneycomb(message = null, opt = null, hasHoneyComb) {
  console.log(
    "%c [ hasHoneyComb ]-38",
    "font-size:13px; background:#C0CF67; color:#495300;",
    hasHoneyComb
  );
  return createValidator(
    "allow to ship Honeycomb",
    message,
    opt,
    function (control, e, validateOpt) {
      if (validateOpt.triggeredByChange && control.incomplete) return null;
      return hasHoneyComb && control.value === "Tasmania"
        ? message ||
            "We are unable to ship any honeycomb products to " +
              control.value +
              " due to quarantine restrictions"
        : null;
    }
  );
}
