/** @jsx jsx */
import { jsx } from "@emotion/core";
import utils from "../../../utils";
// eslint-disable-next-line
import { SiteLink, useGet } from "../../../components";
import { tileStyle } from "./productTileStyle";
import { Fragment, useState, useEffect, useMemo } from "react";
import { createPortal } from "react-dom";
import env from "../../../env";
import { AddToCart } from "../product/addToCart";
import { AiOutlineClose } from "react-icons/ai";
import { useForm } from "../../../form";
// import { Button } from "../../../components";
import { usePost } from "../../../components";
import { useStore } from "../../../store";
import { fb, validators } from "../../../form";

function Portal({ children }) {
  const [el] = useState(document.createElement("div"));
  useEffect(() => {
    let mount = document.getElementById("portal-root");
    mount.appendChild(el);
    return () => mount.removeChild(el);
  }, [el]);
  return createPortal(children, el);
}

export function ProductTile({ product, openClikcPopup = false }) {
  const s = tileStyle;
  const imageUrl = product.thumbnailUrl
    ? utils.site.resourcePath(product.thumbnailUrl)
    : "/assets/product-empty.png";
  const [openProductViewPopup, setOpenProductViewPopup] = useState(false);
  // const productUrl = product.productUrl
  //   ? product.productUrl
  //   : `/product/${product.productSlug}`;
  function openProductViewPopupF() {
    setOpenProductViewPopup(true);
    document.body.style.overflow = "hidden";
  }
  function closeProductViewPopupF() {
    setOpenProductViewPopup(false);
    document.body.style.overflow = "unset";
  }

  return (
    <Fragment>
      {openProductViewPopup && (
        <Portal>
          <ProductViewPopup
            s={s}
            closeProductViewPopupF={closeProductViewPopupF}
            product={product}
          />
        </Portal>
      )}
      <div css={[s.tile]} className='product__tile__item'>
        {openClikcPopup === false ? (
          <div css={s.tileLink} className='product__container'>
            <TileContetns product={product} s={s} imageUrl={imageUrl} />
          </div>
        ) : (
          <div
            css={s.tileLink}
            onClick={() => {
              openProductViewPopupF();
            }}
          >
            <TileContetns product={product} s={s} imageUrl={imageUrl} />
          </div>
        )}
      </div>
    </Fragment>
  );
}

function TileContetns({ product, s, imageUrl }) {
  const post = usePost();
  const model = getFormModel(product, {});
  const form = useForm(model, {
    usePlaceholder: true,
    onControlChanged: onControlChanged,
  });
  const productUrl = product.productUrl
    ? product.productUrl
    : `/product/${product.productSlug}`;
  const { dispatch } = useStore();

  function onControlChanged(e, control) {
    if (!(control.type === "select" && control.parent)) return;
    const newModel = getFormModel(product, form.getValue());
    form.reset(newModel);
  }
  // function addToCart(e) {
  //   form.validateForm(e, () => {
  //     const formVal = form.getValue();
  //     let _variantOptions = [];
  //     for (let i in formVal.variantOptions) {
  //       let _item = {
  //         name: formVal.variantOptions[i].name,
  //         value:
  //           formVal.variantOptions[i][
  //             Object.keys(formVal.variantOptions[i])[1]
  //           ],
  //       };
  //       _variantOptions.push(_item);
  //     }
  //     let _cartId = env.getDevCartId();
  //     const value = {
  //       quantity: formVal.quantity,
  //       variantOptions: _variantOptions,
  //       productId: product.productId,
  //       cartId: _cartId === "null" ? null : _cartId,
  //     };
  //     post.send(env.apiBase + "/api/cart/addToCart", value);
  //   });
  // }
  if (post.done()) {
    env.setDevCartId(post.response.results.cartInfo.cartId);
    if (post.response.errors.length < 1) {
      setTimeout(() => {
        dispatch({
          type: "SET_STATE",
          payload: {
            cartInfo:
              post.response.results.cartInfo.totalQuantity === 0
                ? 0
                : post.response.results.cartInfo.totalQuantity - 1,
          },
        });
      }, 500);
    }
    post.reset();
  }
  const saleCheck = useMemo(() => {
    let checkSale = false;
    if (product.productCategories && product.productCategories.length > 0) {
      for (let i in product.productCategories) {
        if (product.productCategories[i].categoryName === "On Sale") {
          checkSale = true;
          break;
        }
      }
    }
    return checkSale;
  }, [product]);

  return (
    <div style={{ position: "relative", height: "100%" }}>
      <SiteLink
        css={s.container}
        className='productListContainer'
        to={productUrl}
      >
        <div css={s.prdImage} className='product__image__container'>
          <div
            css={s.bg}
            className='product__tile__bg'
            style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}
          ></div>
        </div>
        <SiteLink css={s.name} className='productListName' to={productUrl}>
          {product.productName}
        </SiteLink>
        <div className='productListPrice'>
          {utils.money.formatMoney(product.priceIncTax)} AUD
        </div>
        <div css={s.button} className='productListButton'>
          <SiteLink
            className='button btn btn-primary pageButton'
            to={productUrl}
          >
            Buy Now
          </SiteLink>
        </div>
      </SiteLink>

      {product && product.checkNew && <div css={s.new}>NEW</div>}

      {product && saleCheck === true && <div css={s.sale}>SALE</div>}
    </div>
  );
}

function ProductViewPopup({ s, closeProductViewPopupF, product }) {
  const get = useGet();

  useEffect(() => {
    const url = `/api/product/getProduct/${product.productSlug}`;
    get.send(env.apiBase + url);
    // eslint-disable-next-line
  }, []);

  const data = get.response;

  return (
    <Fragment>
      <div
        css={s.portal__background}
        className='portal__background'
        onClick={closeProductViewPopupF}
      ></div>
      <div css={s.portal__popupcontainer} className='portal__popupcontainer'>
        <div css={s.related__product__head} className='related__product__head'>
          <img src='/assets/logos/logo.png' alt='logo' />
          <div
            style={{
              position: "absolute",
              top: "50%",
              right: "0px",
              transform: "translateY(-50%)",
              color: "white",
              fontSize: "2rem",
              cursor: "pointer",
            }}
            onClick={closeProductViewPopupF}
          >
            <AiOutlineClose />
          </div>
        </div>
        {data ? (
          <div
            css={s.related__product__contents}
            className='related__product__contents row'
          >
            <div
              className='col-12 col-md-5'
              style={{ paddingLeft: "0px", paddingRight: "0px" }}
            >
              <div
                css={s.related__product__img}
                className='related__product__img'
              >
                {/* <img src={data.thumbnailUrl} alt={`img`} /> */}
                <img src={data.thumbnailUrl} alt={`img`} />
              </div>
            </div>
            <div
              className='col-12 col-md-7'
              style={{ backgroundColor: "white" }}
            >
              <h1
                className='related__product__title'
                style={{ textTransform: "uppercase" }}
                css={s.related__product__title}
              >
                <strong>{data.productName}</strong>
              </h1>
              <div
                className='related__product__desc'
                css={s.related__product__desc}
                dangerouslySetInnerHTML={{ __html: data.description }}
              ></div>
              {data.productStatus === 0 && <AddToCart product={data} />}
            </div>
          </div>
        ) : (
          <div
            css={s.related__product__contents}
            className='related__product__contents row'
          >
            <div className='col-12'> Loading ... </div>
          </div>
        )}
      </div>
    </Fragment>
  );
}

function getFormModel(product, formValues /* for reset */) {
  const variantAttributes = product.variantAttributes;

  const model = fb.group({
    quantity: [
      formValues.quantity || 1,
      [validators.Required()],
      { label: "Quantity", type: "number" },
    ],
    variantOptions: fb.array([]),
  });
  const variantOptionArray = model.find("variantOptions").controls;
  (variantAttributes || []).forEach((attr) => {
    variantOptionArray.push(
      newVariantAttribute(attr, formValues, product.variants)
    );
  });
  return model;
}
function newVariantAttribute(attr, formValues, variants) {
  const dyName = attr.name.toLowerCase();
  const optionsSelected = formValues.variantOptions || [];
  const currentSelection = utils.array.find(
    optionsSelected,
    (x) => attr.name === x.name
  );

  const filtered = variants.filter((x) =>
    matchVariantOptions(optionsSelected, x.variantOptions, attr.name)
  );
  const availableOptions = getAvailableOptionValues(filtered, attr.name);
  const options = attr.options.map((x) => {
    return {
      ...x,
      ...{ disabled: !availableOptions.some((o) => o === x.value) },
    };
  });

  return fb.group({
    name: [attr.name, [], { type: "hidden" }],
    [dyName]: [
      (currentSelection && currentSelection.value) ||
        attr.value ||
        options[0].value,
      [validators.Required()],
      {
        //label: attr.name,
        label: null,
        type: "radioList",
        //options : choices
        options: [...options].map((x) => {
          return {
            name:
              (x.displayName || x.value) +
              (x.disabled || false ? " - not available" : ""),
            value: x.value,
            disabled: x.disabled || false,
          };
        }),
      },
    ],
  });
}
function getAvailableOptionValues(variants, name) {
  const values = variants
    .map((x) => {
      const options = x.variantOptions.filter((o) => o.name === name);
      return options && options[0].value;
    })
    .filter((x) => x);

  return Array.from(new Set(values));
}
function matchVariantOptions(
  optionsSelected,
  optionsInVariant,
  currentControlName
) {
  //console.log(optionsSelected, optionsInVariant, currentControlName)
  for (let i = 0; i < optionsSelected.length; i++) {
    let optionSelected = optionsSelected[i];
    if (optionSelected.value === "") {
      // skip match test
      continue;
    }
    if (optionSelected.name === currentControlName) {
      // skip match test for the just selected value
      continue;
    }
    let found = false;
    for (let j = 0; j < optionsInVariant.length; j++) {
      let optionInVariant = optionsInVariant[j];
      if (
        optionSelected.name === optionInVariant.name &&
        optionSelected.value === optionInVariant.value
      ) {
        found = true;
        break;
      }
    }
    if (!found) return false;
  }
  return true;
}
