import { fb, validators } from "../../../form";
import { allowedPostcode } from "./validators/required";

export function getShippingFormData(cart) {
  const data = cart || {};

  const shippingMethods = [{ name: "Shipping", value: "Standard" }];

  const shippingCountry = [{ name: "Australia", value: "Australia" }];

  const model = fb.group({
    billingInfo: address(data.billingAddress),
    shippingInfo: address(data.shippingAddress),
    shippingMethod: [
      data.shippingMethod || "",
      [],
      {
        label: null,
        type: "radioList",
        options: shippingMethods,
        style: { inline: true },
      },
    ],
    shippingCountry: [
      data.shippingCountry || "Australia",
      [],
      {
        label: null,
        type: "radioList",
        options: shippingCountry,
        style: { inline: true },
      },
    ],
    useSameAddress: [
      data.useSameAddress || false,
      [],
      { label: `Use the same address`, type: "checkbox" },
    ],
  });
  return model;
}

export function address(model, hasHoneyComb) {
  const data = model || {};
  const notAllowedPostcode = ["5223", "5222", "5220", "5221"];
  let allowedState = [
    "New South Wales",
    "Victoria",
    "Queensland",
    "South Australia",
    "Tasmania",
    "Australian Capital Territory",
  ].map((x) => {
    return { name: x, value: x };
  });

  return fb.group({
    firstName: [
      data.firstName || "",
      [validators.Required()],
      { label: "First Name", type: "text" },
    ],
    surname: [
      data.surname || "",
      [validators.Required()],
      { label: "Surname", type: "text" },
    ],
    street1: [
      data.street1 || "",
      [validators.Required()],
      { label: "Street", type: "text" },
    ],
    street2: [data.street2 || "", [], { label: null, type: "text" }],
    suburb: [
      data.suburb || "",
      [validators.Required()],
      { label: "Suburb", type: "text" },
    ],
    state: [
      data.state || "",
      [validators.Required("Please select State or Territory.")],
      {
        label: "State",
        type: "select",
        options: [{ name: "Select State", value: "" }, ...allowedState],
      },
    ],
    postcode: [
      (data.postcode || "") && notAllowedPostcode.includes(data.postcode),
      [validators.Required(), allowedPostcode()],
      { label: "Postcode", type: "text" },
    ],
    email: [
      data.email || "",
      [validators.Required(), validators.Email()],
      { label: "Email Address", type: "email" },
    ],
    mobile: [
      data.mobile || "",
      [validators.Required()],
      { label: "Mobile", type: "tel" },
    ],
  });
}
