/** @jsx jsx */
import { jsx } from "@emotion/core";
import style from "./hamburgerStyle";

export function Hamburger({ opened, toggleMenu, scrolled }) {
  return (
    <button
      css={[style.button, scrolled && style.buttonScrolled]}
      type='button'
      aria-label='Menu'
      onClick={toggleMenu}
    >
      <span css={style.hamburger}>
        <span
          css={[
            style.line1,
            opened && style.lineActive1,
            scrolled && style.lineScrolled,
          ]}
        ></span>
        <span
          css={[
            style.line2,
            opened && style.lineActive2,
            scrolled && style.lineScrolled,
          ]}
        ></span>
        <span
          css={[
            style.line3,
            opened && style.lineActive3,
            scrolled && style.lineScrolled,
          ]}
        ></span>
      </span>
    </button>
  );
}
