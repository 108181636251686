import React, { useState } from "react";
import cmsUtils from "../../utils/cmsUtils";
import utils from "../../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

export function AccordionPanel(props) {
  const item = props.item;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};
  const bgColor = cmsUtils.payload(item, "BgColor");
  const imageUrl = cmsUtils.payload(item, "ImageUrl");
  const backgroundTile = cmsUtils.payload(item, "BackgroundTile");
  const bgTheme = cmsUtils.payload(item, "TextColorTheme") || "white";
  const style = cmsUtils.payload(item, "CssStyle");

  const [selected, setSelected] = useState(null);
  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  let bgUrl = null;
  if (imageUrl) bgUrl = utils.site.resourcePath(imageUrl);
  else if (backgroundTile)
    bgUrl = utils.site.resourcePath(
      `/dist/transparent-textures/textures/${backgroundTile}`
    );
  const cssClass = utils.classNames(
    "cms_item",
    "accordionPanel",
    "contentPanel--" + bgTheme,
    item.cssClass || item.anchorName || ""
  );

  var styleSheet = document.createElement("style");
  styleSheet.innerText = style;
  document.head.appendChild(styleSheet);

  const subItemsElts = subItems.map((subItem, index) => (
    <div className='wrapper' key={index}>
      <div className={selected === index ? "accordion show" : "accordion"}>
        <div className='item'>
          <div
            className={selected === index ? "title show" : "title"}
            onClick={() => toggle(index)}
          >
            <h4>{subItem.payloads[0].value}</h4>
            <span className='open_button'>
              <FontAwesomeIcon
                icon={faAngleDown}
                style={{ color: "#454345" }}
              />
            </span>
          </div>
          <div className={selected === index ? "content show" : "content"}>
            <div
              className='content_text'
              dangerouslySetInnerHTML={{ __html: subItem.payloads[1].value }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  ));

  return (
    <>
      <section
        className={cssClass}
        style={{
          backgroundColor: `${bgColor}`,
          backgroundImage: bgUrl && utils.css.bgUrlStyle(bgUrl),
        }}
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
        id={item.anchorName}
      >
        <div className='container'>
          <div className='accordion_container'>{subItemsElts}</div>
        </div>
      </section>
    </>
  );
}
