const overloading = {
  breakpointNames: ["sm", "md", "lg", "xl", "xxl"],
  breakpoints: [576, 768, 992, 1200, 1600],
};

const colors = {
  colorBlue: "#007bff",
};

const variables = {
  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"proxima-nova",serif',

  primaryColor: colors.colorBlue,
  fontColor: "#454345",
  bgColor: "#ebebeb",

  ...colors,
  ...overloading,
};

export default variables;
