/** @jsx jsx */
import { jsx } from "@emotion/core";
import style from "./productPageTilesStyle";
import { useEffect, useState } from "react";
import utils from "utils";
import cmsUtils from "../../cmsUtils";
import env from "../../../../env";
import { Loading } from "components";
import { ProductTile } from "../../../ecm/productList/productTile";
import Slider from "react-slick";
import { Fragment } from "react";

export function ProductPageTiles(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const selectedPages = cmsUtils.payload(item, "SelectedPages") || "";
  const useSlider = cmsUtils.payload(item, "UseSlider") || "default";
  const cssClass = utils.classNames(
    "cms_item",
    "product__page__tiles",
    useSlider,
    item.cssClass || item.anchorName || ""
  );
  const [pages, setPages] = useState();
  const [searchPedding, setSearchPedding] = useState(true);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetchData = async () => {
      const res = await fetch(
        env.apiBase + "/api/product/getproductpagetilesitem",
        {
          method: "post",
          signal: signal,
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            selectedPages: selectedPages,
          }),
        }
      );

      const data = await res.json();
      setPages(data);
      setSearchPedding(false);
    };

    fetchData();
    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);

  return (
    <div
      css={style.product__page__tiles}
      className={cssClass}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
    >
      {searchPedding ? (
        <Loading></Loading>
      ) : (
        <Fragment>
          {useSlider === "default" ? (
            <div
              css={style.product__page__tiles__container}
              className='product__page__tiles__container'
            >
              {pages.map((item, index) => {
                return <ProductTile key={index} product={item} />;
              })}
            </div>
          ) : (
            <ProductPageTilesWithSlider pages={pages} />
          )}
        </Fragment>
      )}
      {pages && pages.length < 1 && (
        <div css={style.noResultText}>
          {" "}
          Sorry, there are no available results.{" "}
        </div>
      )}
    </div>
  );
}

function ProductPageTilesWithSlider({ pages }) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 3000,
    fade: false,
    autoplay: true,
    slidesToShow: pages.length >= 4 ? 4 : pages.length,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: pages.length >= 2 ? 2 : pages.length,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {pages.map((item, index) => {
        return <ProductTile key={index} product={item} />;
      })}
    </Slider>
  );
}
