import { mq } from "../../../cssInJs";
export default {
  summary: mq({
    marginLeft: [0, null, 30],
    border: "solid 1px #333",
    padding: "0.5rem",
    color: "white",
    borderRadius: " 0.5rem",
    background: "#555",
    marginBottom: "0.5rem",
  }),
  totalHdr: {
    fontSize: "1.1rem",
    marginBottom: "0.5rem",
  },
  company: {
    fontSize: "1.1rem",
    fontWeight: "bold",
    marginBottom: "0.7rem",
  },
  totalAmount: {
    fontSize: "1.1rem",
    fontWeight: "bold",
  },
  pickupTime: {
    fontSize: "0.7",
  },
};
